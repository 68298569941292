import styled from "styled-components"

export default styled.span`
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  position: relative;
  ${(props) => (props.right ? "right: -0.5rem;" : "left: -0.5rem;")}
  font-weight: 600;
  box-decoration-break: clone;

  a,
  a:visited {
    color: inherit;
  }

  :hover {
    background-color: ${(props) => props.color || "var(--brand)"};
    color: #fafafa;

    a,
    a:visited {
      color: #fafafa;
    }
  }
`
