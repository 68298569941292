import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ColorWrapper from "../components/ColorWrapper"

const Title = styled.h3`
  margin-bottom: 0.25rem;
`

const ExtraData = styled.span`
  display: block;
  font-size: 0.8rem;
`

const Article = styled.article`
  margin-top: 2rem;
`

const Description = styled.p`
  margin: 0.5rem 0 0;
`

const BlogIndex = ({ data }) => {
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout>
      <SEO title="All posts" />
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <Article key={node.fields.slug}>
            <header>
              <Title>
                <ColorWrapper>
                  <Link to={node.fields.slug}>{title}</Link>
                </ColorWrapper>
              </Title>
              <ExtraData>{node.frontmatter.date}</ExtraData>
            </header>
            <section>
              <Description
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </section>
          </Article>
        )
      })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { slug: { regex: "/^/blog/" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "Do MMMM, YYYY", locale: "en-GB")
            title
            description
          }
        }
      }
    }
  }
`
